<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex flex-column mx-auto">
        <div class="w-100">
          <img :src="uwv_img" class="w-75 mb-4" v-if="uwv" alt="" />
          <img :src="digid_img" class="w-75 mb-4" v-else alt="" />

          <h1>{{ $t("Alternative method needed") }}</h1>
          <p class="fw-medium text-gray">
            {{
              $t(
                "Right now, there seems to be a problem with the DigiD services. No worries, we are able to verify your identity using  an alternative method."
              )
            }}
          </p>

          <div class="alert alert-warning" role="alert">
            <strong> Make sure your MijnOverheid account is activated </strong>
            {{
              $t(
                "by visiting mijn.overheid.nl and login with DigiD. You can use also use our alternative method or just try again."
              )
            }}
          </div>

          <div class="w-100 mt-4">
            <button
              class="btn btn-primary btn-block"
              @click="proceed"
              :disabled="getLoadings.tenant"
            >
              <b-spinner
                v-if="getLoadings.tenant"
                small
                label="Spinning"
                variant="white"
              ></b-spinner>

              Proceed with the identification
            </button>
            <button class="btn btn-outline-white btn-block mt-3" @click="tryDigid">
              {{ $t("Try Digid login again") }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <div class="step__rightSection mx-auto">
        <RightPoints active="4" />
      </div>
    </template>
  </AuthLayoutContent>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import RightPoints from "../../Expat/RightPoints.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
export default {
  data() {
    return {
      uwv_img: require("../../../../assets/images/onboarding/uwv.png"),
      digid_img: require("../../../../assets/images/onboarding/digid.png"),
    };
  },
  components: { RightPoints, AuthLayoutContent },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("tenant", ["tenant"]),
    uwv() {
      return this.$route.name == "DigidUWVFailed";
    },
  },
  async mounted() {
    let tenant_id = this.$route.params.tenant_id;
    if (tenant_id) {
      await this.getTenant(tenant_id);
    }
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    async proceed() {
      this.updateTenant({ digid_failed: true }).then(() => {
        if (this.uwv) {
          this.$router.push({
            name: "ExpatStep7",
            params: { tenant_id: this.tenant.id },
          });
        } else {
          this.$router.push({
            name: "ExpatStep6",
            params: { tenant_id: this.tenant.id },
          });
        }
      });
    },
    async tryDigid() {
      if (this.uwv) {
        this.$router.push({
          name: "DigidUWV",
          params: { tenant_id: this.tenant.id },
        });
      } else {
        this.$router.push({
          name: "Digid",
          params: { tenant_id: this.tenant.id },
        });
      }
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}

.vue-tel-input {
  padding: 5px;
}

.digidAction {
  border: 1px solid #e1e1e1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.digidAction:hover {
  background: #e1e1e1 !important;
}
</style>
